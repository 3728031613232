<template>
  <div class="team-area ptb-70 bg-f7fafd">
    <div class="container">
      <div class="section-title">
        <h2>Перелік будинків</h2>
        <div class="bar"></div>
        <p>
          Ви можете знайти всю необхідну інформацію натиснувши на зображення
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div class="single-team-member">
            <div class="member-image">
              <img
                src="../../assets/images/vorzel/intro-vorzel.webp"
                alt="image"
              />

              <ul class="social">
                <li>
                  <a
                    href="https://goo.gl/maps/ULsrXzQem2tdbcm78"
                    target="_blank"
                    class="btn btn-primary"
                    >Повна інформація</a
                  >
                </li>
              </ul>
            </div>

            <div class="member-content">
              <h3>Ворзель</h3>
              <span>Пансіонат</span>
              <p>
                Ворзель це чудове курортне містечко поруч із Києвом. Наш будинок
                для літніх людей у Ворзелі огорне ваших рідних теплом, турботою
                і свіжим цілющим повітрям. Сучасний будинок у
                санаторно-курортній зоні з великою зеленою територією та
                відпочинковою зоною. Затишні кімнати з усім необхідним для
                максимального комфорту. Також є домашні улюбленці для гарного
                настрою.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="single-team-member">
            <div class="member-image">
              <img src="../../assets/images/zazime/10.webp" alt="image" />

              <ul class="social">
                <li>
                  <a
                    href="https://goo.gl/maps/c4gf45ZPtE1VGo8VA"
                    target="_blank"
                    class="btn btn-primary"
                    >Повна інформація</a
                  >
                </li>
              </ul>
            </div>

            <div class="member-content">
              <h3>Зазим'є</h3>
              <span>Пансіонат</span>
              <p>
                Пансіонат для людей похилого віку в Зазим'ї працює вже кілька
                років. Згуртований колектив та відмінні умови для життя чекає на
                нових постояльців нашого будинку для людей похилого віку.
                Прекрасний будинок з камінною кімнатою, зеленою територією та
                альтанками для відпочинку. Кімнати, уважний досвідчений персонал
                та домашня атмосфера.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="single-team-member">
            <div class="member-image">
              <img src="../../assets/images/letki/letki.webp" alt="image" />

              <ul class="social">
                <li>
                  <a
                    href="https://goo.gl/maps/hjxGMdvEsRdHmTru6"
                    target="_blank"
                    class="btn btn-primary"
                    >Повна інформація</a
                  >
                </li>
              </ul>
            </div>

            <div class="member-content">
              <h3>Літки</h3>
              <span>Пансіонат</span>
              <p>
                Влітку 2022 року ми відкрили велике відділення будинку для людей
                похилого віку "ЛЮБОВ" у Літках, біля Києва. У відділенні
                зроблено ремонт, все обладнано для життя наших бабусь та
                дідусів. Це не просто будинок для людей похилого віку, це цілий
                сучасний медичний комплекс з лікарями на постійній основі,
                стаціонаром та маніпуляційним кабінетом. Крім того, велика
                зелена зона з альтанками та котиками подарують чудовий настрій
                нашим гостям.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="single-team-member">
            <div class="member-image">
              <img
                src="../../assets/images/brovari-1/intro-brovary-1.webp"
                alt="image"
              />

              <ul class="social">
                <li>
                  <a
                    href="https://goo.gl/maps/eBvR4PdtvYRyFZKAA"
                    target="_blank"
                    class="btn btn-primary"
                    >Повна інформація</a
                  >
                </li>
              </ul>
            </div>

            <div class="member-content">
              <h3>Бровари 1</h3>
              <span>Пансіонат</span>
              <p>
                Будинок для людей похилого віку в Броварах знаходиться в
                хорошому районі і оснащений усім необхідним для догляду за
                літніми людьми. Співробітники центру чекають на вас у гості у
                будь-який час. Будинок має дуже зручне розташування у центрі
                міста. Але при цьому чудова зелена зона навколо та домашні
                улюбленці для дозвілля гостей. Кожна кімната світла та приємна з
                функціональними меблями та всім необхідним для життя наших
                мешканців.
              </p>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-6 col-md-6">
          <div class="single-team-member">
            <div class="member-image">
              <img src="../../assets/images/brovari-2/35.webp" alt="image" />

              <ul class="social">
                <li>
                  <a
                    href="https://goo.gl/maps/uQT4PL3QEXaWLBPt6"
                    target="_blank"
                    class="btn btn-primary"
                    >Повна інформація</a
                  >
                </li>
              </ul>
            </div>

            <div class="member-content">
              <h3>Бровари 2</h3>
              <span>Пансіонат</span>
              <p>
                Нещодавно ми відкрили в броварах друге відділення будинку для
                людей похилого віку, обладнали його всім необхідним і підібрали
                відмінну команду співробітників, щоб забезпечити найкращий
                догляд нашим бабусям та дідусям. Будинок має дуже зручне
                розташування у центрі міста. Але при цьому чудова зелена зона
                навколо та домашні улюбленці для дозвілля гостей. Кожна кімната
                світла та приємна з функціональними меблями та всім необхідним
                для життя наших мешканців.
              </p>
            </div>
          </div>
        </div> -->

        <div class="col-lg-6 col-md-6">
          <div class="single-team-member">
            <div class="member-image">
              <img
                src="../../assets/images/borshagovka/front_borshagovka.webp"
                alt="image"
              />
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/10.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/11.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/12.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/13.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/14.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/15.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/16.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/17.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/18.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/19.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/2.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/20.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/3.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/4.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/5.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/6.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/7.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/8.webp"
              ></a>
              <a
                data-fancybox="gallery"
                href="https://love-dp.com.ua/images/gallery/borschagovka/9.webp"
              ></a>

              <ul class="social">
                <li>
                  <a
                    class="btn btn-primary"
                    title="Борщагівка"
                    data-fancybox="gallery"
                    href="https://love-dp.com.ua/images/gallery/borschagovka/1.webp"
                    >Галерея</a
                  >
                </li>
                <li>
                  <a
                    href="https://goo.gl/maps/wgZ5tD7eosfoVLES6"
                    target="_blank"
                    class="btn btn-primary"
                    >Карта</a
                  >
                </li>
              </ul>
            </div>

            <div class="member-content">
              <h3>Петропавлівська Борщагівка</h3>
              <span>Пансіонат</span>
              <p>
                Нещодавно ми відкрили в Петропавлівській Борщагівці відділення
                будинку для людей похилого віку, обладнали його всім необхідним
                і підібрали відмінну команду співробітників, щоб забезпечити
                найкращий догляд нашим бабусям та дідусям. Будинок має дуже
                зручне розташування у центрі міста. Але при цьому чудова зелена
                зона навколо та домашні улюбленці для дозвілля гостей. Кожна
                кімната світла та приємна з функціональними меблями та всім
                необхідним для життя наших мешканців.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
};
</script>
